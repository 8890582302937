import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { validateEmail } from "../../common/function";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [signinErrorMessage, setSigninErrorMessage] = useState("");
  const [rememberPassword, setRememberPassword] = useState(false);

  const onEmailChanged = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const onPasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  const createAccount = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const signin = (e) => {
    e.preventDefault();

    if (handleValidation()) {
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!validateEmail(email)) {
      errors["email"] = "Email address is invalid";
      formIsValid = false;
    }
    if (password == "") {
      errors["password"] = "Password cannot be empty";
      formIsValid = false;
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    navigate("/financing/forgotpassword");
  };

  return (
    <div className="mt-[81px] mb-[200px]">
      <div className="h-full w-[90%] sm:w-[450px] mx-auto  pt-[5px] animate__animated  animate__slideInUp">
        <p className="font-helveticaRomanRoman text-default-color text-[2.1875em] text-center">Login</p>
        <div className="mt-2 flex items-center justify-center">
          <span className="font-helveticaRoman text-[#393939] text-[1.0625em] mr-[10px]">Don't have an account?</span>
          <a href="#" onClick={createAccount} className="decoration-[#e54335]">
            <span className="font-helveticaRoman text-[#e54335] text-[1.0625em]">Create here</span>
          </a>
        </div>
        <div className="mt-5">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Email address</span>
          <input
            value={email}
            type="email"
            onChange={onEmailChanged}
            className="w-[100%] h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["email"]}</span>
        </div>
        <div className="mt-5">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Password</span>
          <input
            value={password}
            type="password"
            onChange={onPasswordChanged}
            className="w-[100%] h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["password"]}</span>
        </div>
        <div className="mt-5 flex items-center justify-between">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="w-[22px] h-[22px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mr-[5px]"
            />
            <label className="font-helveticaRoman text-[#737171] text-[0.9375em]">Remember Password</label>
          </div>

          <a href="#" onClick={forgotPassword} className="decoration-black underline">
            <span className="font-helveticaRoman text-[0.9375em]">Forgot Password?</span>
          </a>
        </div>
        <p className="text-red-500 mt-[30px]">{signinErrorMessage}</p>
        <a
          href="#"
          onClick={signin}
          className="bg-default-color w-full h-[52px] rounded-md flex items-center justify-center mt-[40px]"
        >
          <span className="font-helveticaRoman text-[1.125em] text-white">Sign In</span>
        </a>
      </div>
    </div>
  );
}

export default Login;
