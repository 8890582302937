import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";
import Hamburger from "hamburger-react";
import LoginButton from "../loginbutton";
import styles from "./style.module.css";

function Header() {
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuTransitionClass, setMenuTransitionClass] = useState("");
  const [mobileMenuShown, setMobileMenuShown] = useState(false);

  const contactUsClicked = (e) => {
    navigate("/contactus");
  };

  const newsClicked = (e) => {
    navigate("/news");
  };

  const pricingClicked = (e) => {
    navigate("/pricing");
  };

  const faqClicked = (e) => {
    navigate("/faq");
  };

  const aboutUsClicked = (e) => {
    navigate("/aboutus");
  };

  const demoClicked = (e) => {
    navigate("/demo");
  };

  const investorsClicked = (e) => {
    navigate("/investors");
  };

  const companyMouseEnter = () => {
    setShowDropDown(true);
  };
  const companyMouseLeave = () => {
    setShowDropDown(false);
  };

  const logoClicked = (e) => {
    navigate("/");
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuClicked = (toggled) => {
    console.log("toggled: ", toggled);
    setMobileMenuShown(toggled);
    if (toggled) {
      setMenuTransitionClass(`${styles.menuOpened}`);
      document.body.style.overflow = "hidden";
    } else {
      setMenuTransitionClass(`${styles.menuClosed}`);
      document.body.style.overflow = "auto";
    }
  };

  return (
    <div className="h-[83px] bg-default-color w-full relative px-[40px]">
      {/* mobile menu */}
      <div
        className={`fixed w-0 h-screen right-0 top-0 bg-default-color z-20 overflow-hidden flex flex-row justify-center	 ${menuTransitionClass}`}
      >
        <div className="w-[250px] h-[320px] flex flex-col items-start justify-between relative gap-[20px] mt-[100px]">
          {/* <LoginButton loginClick={() => menuClicked(false)} /> */}
          <div
            onClick={() => {
              demoClicked();
              menuClicked(false);
            }}
            className="cursor-pointer"
          >
            <span className="font-helveticaBold text-[1.5em] text-white">Demo</span>
          </div>
          <div
            onClick={() => {
              pricingClicked();
              menuClicked(false);
            }}
            className="cursor-pointer"
          >
            <span className="font-helveticaBold text-[1.5em] text-white">Pricing</span>
          </div>
          <div
            onClick={() => {
              faqClicked();
              menuClicked(false);
            }}
            className="cursor-pointer"
          >
            <span className="font-helveticaBold text-[1.5em] text-white">FAQ</span>
          </div>
          {/* <div
            onClick={() => {
              investorsClicked();
              menuClicked(false);
            }}
            className="cursor-pointer"
          >
            <span className="font-helveticaBold text-[1.5em] text-white">Institutional Investors</span>
          </div> */}

          <div
            className="w-full cursor-pointer"
            onClick={() => {
              aboutUsClicked();
              menuClicked(false);
            }}
          >
            <span className="font-helveticaBold text-[1.5em] text-white">About Us</span>
          </div>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              newsClicked();
              menuClicked(false);
            }}
          >
            <span className="font-helveticaBold text-[1.5em] text-white">News</span>
          </div>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              contactUsClicked();
              menuClicked(false);
            }}
          >
            <span className="font-helveticaBold text-[1.5em] text-white">Contact Us</span>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 top-[100%] w-full h-[2px] bg-white" /> {/* white line at the bottom */}
      <div className="mx-auto h-full flex items-center justify-between max-w-[1246px] gap-[30px]">
        <div className="cursor-pointer" onClick={logoClicked}>
          <img src="/images/common/logo.png" className="w-[154px]" />
        </div>

        {windowWidth < 700 ? (
          <div className="flex items-center gap-[10px]">
            <LoginButton />
            <div className="z-30">
              <Hamburger
                duration={0.5}
                toggled={mobileMenuShown}
                // toggle={() => alert("toggle")}
                onToggle={menuClicked}
                color="#FFFFFF"
                on
              />
            </div>
          </div>
        ) : (
          <div className="w-[627px] flex items-center justify-between relative h-full">
            <div onClick={demoClicked} className="cursor-pointer">
              <span className="font-helveticaRoman text-header-text-color">Demo</span>
            </div>
            <div onClick={pricingClicked} className="cursor-pointer">
              <span className="font-helveticaRoman text-header-text-color">Pricing</span>
            </div>
            <div onClick={faqClicked} className="cursor-pointer">
              <span className="font-helveticaRoman text-header-text-color">FAQ</span>
            </div>
            {/* <div onClick={investorsClicked} className="cursor-pointer">
              <span className="font-helveticaRoman text-header-text-color">Institutional Investors</span>
            </div> */}
            <div className="h-full flex items-center">
              <div
                className="flex items-center cursor-pointer relative"
                onMouseEnter={companyMouseEnter}
                onMouseLeave={companyMouseLeave}
              >
                <span className="font-helveticaRoman text-header-text-color mr-[14px]">Company</span>
                <img src="/images/header/header_arrow.png" />

                {showDropDown && (
                  <div className="absolute z-10 w-[145px] h-[140px] bg-default-color top-[20px] flex flex-col items-center justify-between px-[10px] pb-[10px] pt-[40px]">
                    <div className="w-full cursor-pointer" onClick={aboutUsClicked}>
                      <span className="text-white text-base font-helveticaRoman">About Us</span>
                    </div>
                    <div className="w-full cursor-pointer" onClick={newsClicked}>
                      <span className="text-white text-base font-helveticaRoman">News</span>
                    </div>
                    <div className="w-full cursor-pointer" onClick={contactUsClicked}>
                      <span className="text-white text-base font-helveticaRoman">Contact Us</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <LoginButton />
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
