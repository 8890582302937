import React, { useState } from "react";
import ContactUsForm from "../../components/ContactusForm";

function Contact() {
  return (
    <div>
      {/* <div className="flex items-center justify-center relative w-full h-[450px]" onLoad={addAnimation}>
        <img
          src="/images/contactus/contactus_bg.png"
          onLoad={addAnimation}
          className={`w-full h-full object-cover object-center absolute  ${imageAnimationStyle}`}
        />
        <div className="z-10">
          <p className="font-helveticaLight text-[3.125em] text-white text-center">Get Results. Contact Us Today</p>
          <p className="font-helveticaLight text-[1.25em] text-white text-center mt-[15px]">
            Your brand’s success matters to us. Contact us to find out how our digital experts can help
          </p>
          <p className="font-helveticaLight text-[1.25em] text-white text-center">
            you take your brand to the next level.
          </p>
        </div>
      </div> */}

      <div className="bg-gray-bg-color w-full py-[100px]">
        <ContactUsForm />
      </div>
    </div>
  );
}

export default Contact;
