import React, { useState } from "react";
import AboutUsContent from "../../components/AboutusContent";

function AboutUs() {
  return (
    <div className="bg-[#F7F6FA] w-full py-[100px]">
      <AboutUsContent />
    </div>
  );
}

export default AboutUs;
