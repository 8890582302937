import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

function DescriptionOfSecurities() {
  useEffect(() => {});

  return (
    <div className="mt-[81px] mb-[200px]">
      <p className="text-center">DescriptionOfSecurities</p>
    </div>
  );
}

export default DescriptionOfSecurities;
