import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const logOut = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mb-[1000px]">
      <p>Home page</p>
      <a href="#" onClick={logOut} className="decoration-[#e54335] ">
        <span className="font-helveticaRoman text-[#e54335] text-[1.0625em]">Log Out</span>
      </a>
    </div>
  );
}

export default Home;
