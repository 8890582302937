const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem("financing_accessToken");
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem("financing_accessToken", token);
};

const getUser = () => {
  const user = JSON.parse(localStorage.getItem("financing_user"));
  return user;
};

const setUser = (user) => {
  localStorage.setItem("financing_user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("financing_user");
};

const removeAccessToken = () => {
  localStorage.removeItem("financing_accessToken");
};

const TokenService = {
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  removeAccessToken,
};

export default TokenService;
