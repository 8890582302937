import React from "react";
import { useNavigate } from "react-router-dom";

function LogoutButton({ onClick }) {
  const navigate = useNavigate();

  const logoutClicked = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a
      href="#"
      className="w-[90px] h-[36px] border rounded-[18px] flex items-center justify-center"
      onClick={logoutClicked}
    >
      <span className="text-white text-base font-helveticaBold">LOG OUT</span>
    </a>
  );
}

export default LogoutButton;
