import api from "./api";
import TokenService from "./token.service";

const register = (email, password) => {
  return api.post("/api/auth/register", {
    email,
    password,
  });
};

const verifyEmail = (token) => {
  return api.get("/api/auth/verify/" + token);
};

const login = (email, password) => {
  return api.post(
    "/api/auth/login",
    {
      email,
      password,
    },
    { withCredentials: true }
  );
};

const logout = () => {
  TokenService.removeUser();
  TokenService.removeAccessToken();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("financing_user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  verifyEmail,
};

export default AuthService;
