import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import {
  setNameAndAddress,
  refreshToken,
  getAllInvestors,
  updateNDASignedState,
  updateNDASentState,
} from "src/pages/Financing/Services/ApiService";
import { Role } from "src/common/constants";
import { setUser } from "src/redux/slices/financingAuthSlice";
import UserService from "../Services/user.service";
import TokenService from "../Services/token.service";
import { API_URL, handleError } from "src/common/function";

function FinancingHome() {
  const [fullName, setFullName] = useState("");
  // const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [showState, setShowState] = useState(0);

  // const accessToken = useSelector((state) => state.financingAuth.token);
  // const user = useSelector((state) => state.financingAuth.user);
  const [investors, setInvestors] = useState([]);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const user = TokenService.getUser();
    console.log(user);
    if (user?.role.name == Role.ADMINISTRATOR) {
      setShowState(3);
      getInvestors();
    } else {
      if (user.fullName && user.address) {
        if (user.isNDASent) {
          if (user.isNDASigned) {
            navigate("/financing/materials");
            // setShowState(4); //confidential data
          } else {
            setShowState(2); //questions? email: info@admiral.trade
          }
        } else {
          setShowState(1); // you will receive NDA with further instructions
        }
      } else {
        setShowState(0); // name and address form
      }
    }
  }, []);

  const getInvestors = () => {
    UserService.getInvestors()
      .then(function (response) {
        console.log("investors: ", response.data.users);
        const investorList = response.data.users;
        for (let i = 0; i < investorList.length; i++) {
          const date = new Date(investorList[i].createdAt);
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZone: "UTC",
          };
          const formattedDate = date.toLocaleString("en-US", options);
          investorList[i].createdAt = formattedDate;
        }
        setInvestors(investorList);
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          toast(error.response.data.message);
          navigate("/financing/login");
        } else {
          toast("Something went wrong!");
        }
      });

    // try {
    //   const response = await getAllInvestors(token);
    //   console.log("investors: ", response.data.users);
    //   const investorList = response.data.users;
    //   for (let i = 0; i < investorList.length; i++) {
    //     const date = new Date(investorList[i].createdAt);
    //     const options = {
    //       year: "numeric",
    //       month: "long",
    //       day: "numeric",
    //       hour: "numeric",
    //       minute: "numeric",
    //       second: "numeric",
    //       timeZone: "UTC",
    //     };
    //     const formattedDate = date.toLocaleString("en-US", options);
    //     investorList[i].createdAt = formattedDate;
    //   }
    //   setInvestors(investorList);
    // } catch (error) {
    //   console.log("error: ", error);
    //   if (error.response.status == 401) {
    //     const newToken = await refreshToken();
    //     getInvestors(newToken);
    //     console.log("Access token is expired: ", error);
    //   } else {
    //     toast("Something went wrong!");
    //     console.log("set name and address API error: ", error);
    //   }
    // }
  };

  const onSendClick = async (e) => {
    const address = `${address1} ${address2} ${address3}`;

    if (fullName.length === 0 || address.length === 0) {
      return;
    }

    const user = TokenService.getUser();
    UserService.setNameAndAddress(user.email, fullName, address)
      .then(function (response) {
        if (response.status == 201) {
          console.log(response);
          toast("Name and address submitted!");
          setShowState(1);
          TokenService.setUser(response.data);
        }
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          toast(error.response.data.message);
          navigate("/financing/login");
        } else {
          toast("Something went wrong!");
        }
      });
  };

  const handleNDASentChange = (e, index) => {
    const investor = investors[index];

    const state = e.target.value === "true" ? true : false;

    UserService.updateNDASentState(investor.email, state)
      .then(function (response) {
        if (response.status == 201) {
          console.log(response);
          toast("NDA Sign state changed");
          getInvestors();
        }
      })
      .catch(function (error) {
        console.log("error: ", error);
        if (error.response.status == 401) {
          toast(error.response.data.message);
          navigate("/financing/login");
        } else {
          toast("Something went wrong!");
        }
      });
  };

  const handleNDASignedChange = (e, index) => {
    const investor = investors[index];

    const state = e.target.value === "true" ? true : false;

    UserService.updateNDASignedState(investor.email, state)
      .then(function (response) {
        if (response.status == 201) {
          console.log(response);
          toast("NDA Sign state changed");
          getInvestors();
        }
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          toast(error.response.data.message);
          navigate("/financing/login");
        } else {
          toast("Something went wrong!");
        }
      });
  };

  const deleteInvestor = (index) => {
    console.log("investors[index]: ", investors[index]);

    if (window.confirm("Do you want to delete this entry?")) {
      UserService.deleteInvestor(investors[index].id)
        .then(function (response) {
          console.log("Delete response: ", response);
          if (response.status == 201) {
            getInvestors();
          }
        })
        .catch(function (error) {
          handleError(error);
        });
    }
  };

  return (
    <div>
      {showState === 3 && (
        <div>
          <table className="mx-auto mt-[30px]">
            <thead>
              <tr className="border-b-[1px]">
                <th className="px-[5px] font-helveticaRoman border-r-[1px]">
                  Name
                </th>
                <th className="px-[5px] font-helveticaRoman border-r-[1px]">
                  Email
                </th>
                <th className="px-[5px] font-helveticaRoman border-r-[1px]">
                  Address
                </th>
                <th className="px-[5px] font-helveticaRoman border-r-[1px]">
                  NDA
                  <br />
                  Sent
                </th>
                <th className="px-[5px] font-helveticaRoman border-r-[1px]">
                  NDA
                  <br />
                  Completed
                </th>
                <th className="px-[5px] font-helveticaRoman border-r-[1px]">
                  Registered Date
                </th>
                <th className="px-[5px] font-helveticaRoman"></th>
              </tr>
            </thead>
            <tbody>
              {investors.map((investor, index) => (
                <tr key={index} className="border-b-[1px]">
                  <td className="px-[5px] py-[5px] font-helveticaRoman text-center border-r-[1px]">
                    {investor.fullName}
                  </td>
                  <td className="px-[5px] py-[5px] font-helveticaRoman text-center border-r-[1px]">
                    {investor.email}
                  </td>
                  <td className="px-[5px] py-[5px] font-helveticaRoman text-center border-r-[1px]">
                    {investor.address}
                  </td>
                  <td className="px-[5px] py-[5px] font-helveticaRoman text-center border-r-[1px]">
                    <select
                      value={String(investor.isNDASent)}
                      onChange={(e) => handleNDASentChange(e, index)}
                      className="border border-gray-300"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </td>
                  <td className="px-[5px] py-[5px] font-helveticaRoman text-center border-r-[1px]">
                    <select
                      value={String(investor.isNDASigned)}
                      onChange={(e) => handleNDASignedChange(e, index)}
                      className="border border-gray-300"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </td>
                  <td className="px-[5px] py-[5px] font-helveticaRoman text-center border-r-[1px]">
                    {investor.createdAt}
                  </td>
                  <td>
                    <button
                      className="ml-2 text-red-600"
                      onClick={() => deleteInvestor(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      )}
      {showState === 2 && (
        <>
          <p className="w-[90%] max-w-[700px] mx-auto text-center text-[#393939] font-helveticaRoman text-[1.625em] mt-[100px]">
            NDA is not completed yet.
          </p>
          <p className="w-[90%] max-w-[700px] mx-auto text-center text-[#393939] font-helveticaRoman text-[1.225em] sm:text-[1.625em] mt-[20px]">
            Questions? Email: info@admiral.trade
          </p>
        </>
      )}

      {showState === 1 && (
        <p className="w-[90%] max-w-[700px] mx-auto text-center text-[#393939] font-helveticaRoman text-[1.625em] mt-[100px]">
          Thank you for submitting your name and address. You will <br />
          receive an email shortly regarding the non-disclosure <br />
          agreement with further instructions.
        </p>
      )}

      {showState === 0 && (
        <div className="mt-[100px]">
          <p className="w-full text-center text-[#393939] font-helveticaRoman text-[1.825em] mt-[10px]">
            Please enter your full name and address.
            <br /> This is needed for the non-disclosure agreement.
          </p>
          <div className="bg-white rounded-[5px] px-[10px] sm:px-[30px] py-[40px] w-full max-w-[600px] mx-auto animate__animated animate__slideInUp">
            <div className="flex items-center justify-between gap-[30px] mt-[25px]">
              <p className="font-helveticaRoman text-[#737171] text-[0.9375em] w-[90px]">
                Full Name
              </p>
              <input
                value={fullName}
                type="text"
                onChange={(e) => setFullName(e.target.value)}
                className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
              />
            </div>

            <div className="flex items-center justify-between gap-[30px] mt-[25px]">
              <p className="font-helveticaRoman text-[#737171] text-[0.9375em] w-[90px]">
                Address
              </p>
              <input
                value={address1}
                type="text"
                placeholder="Address line 1"
                onChange={(e) => setAddress1(e.target.value)}
                className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
              />
            </div>

            <div className="flex items-center justify-between gap-[30px] mt-[25px]">
              <p className="font-helveticaRoman text-[#737171] text-[0.9375em] w-[90px]"></p>
              <input
                value={address2}
                type="text"
                placeholder="Address line 2"
                onChange={(e) => setAddress2(e.target.value)}
                className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
              />
            </div>

            <div className="flex items-center justify-between gap-[30px] mt-[25px]">
              <p className="font-helveticaRoman text-[#737171] text-[0.9375em] w-[90px]"></p>
              <input
                value={address3}
                type="text"
                placeholder="Address line 3"
                onChange={(e) => setAddress3(e.target.value)}
                className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
              />
            </div>

            <div
              onClick={onSendClick}
              className="cursor-pointer w-[250px] h-[52px] bg-[#0295FF] rounded-[5px] flex items-center justify-center mt-[40px] mx-auto"
            >
              <span className="font-helveticaRoman text-[1.125em] text-white">
                Submit
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinancingHome;
