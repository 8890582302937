import api from "./api";

const getInvestors = () => {
  return api.get("/api/user/get_investors");
};

const setNameAndAddress = (email, fullName, address) => {
  return api.post("/api/user/set_name_address", {
    email: email,
    full_name: fullName,
    address: address,
  });
};

const updateNDASignedState = (email, state) => {
  return api.post("/api/user/update_nda_signed", {
    email: email,
    state: state,
  });
};

const updateNDASentState = (email, state) => {
  return api.post("/api/user/update_nda_sent", {
    email: email,
    state: state,
  });
};

const deleteInvestor = (userId) => {
  return api.post("/api/user/delete_investor", {
    id: userId,
  });
};

const UserService = {
  getInvestors,
  setNameAndAddress,
  updateNDASignedState,
  updateNDASentState,
  deleteInvestor,
};

export default UserService;
