import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { API_URL, validatePassword } from "src/common/function";

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetButtonTitle, setResetButtonTitle] = useState("Reset Password");

  const resetPassword = (e) => {
    e.preventDefault();

    if (resetButtonTitle === "Back to login") {
      navigate("/financing/login");
    } else {
      if (!validatePassword(password).success) {
        toast(validatePassword(password).message);
        return;
      }
      setIsLoading(true);
      axios
        .post(API_URL + "/api/auth/reset-password", {
          newPasswordToken: token,
          newPassword: password,
        })
        .then(function (response) {
          console.log("response: ", response);
          if (response.status == 200) {
            toast("Password has been reset");
            setResetButtonTitle("Back to login");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          toast(error.response.data.message);

          setIsLoading(false);
        });
    }
  };

  const onPasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChanged = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="mt-[81px] mb-[200px]">
      <div className="h-full w-[90%] max-w-[503px] mx-auto pt-[5px] animate__animated  animate__slideInUp">
        <p className="font-helveticaRomanRoman text-default-color text-[2.1875em] text-center">
          Reset Password
        </p>

        <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">
            Password
          </span>
          <input
            value={password}
            type="password"
            onChange={onPasswordChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
        </div>

        <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">
            Confirm Password
          </span>
          <input
            value={confirmPassword}
            type="password"
            onChange={onConfirmPasswordChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
        </div>

        <a
          href="#"
          onClick={resetPassword}
          className="bg-default-color w-full h-[52px] rounded-md flex items-center justify-center mt-[40px]"
        >
          {isLoading ? (
            <MoonLoader color="#FFFFFF" size={25} />
          ) : (
            <span className="font-helveticaRoman text-[1.125em] text-white">
              {resetButtonTitle}
            </span>
          )}
        </a>
      </div>
    </div>
  );
}

export default ResetPassword;
