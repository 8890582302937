import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";

import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { validateEmail, API_URL } from "src/common/function";
import AuthService from "../Services/auth.service";

function FinancingSignup() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termChecked, setTermChecked] = useState(true);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onEmailChanged = (e) => {
    setEmail(e.target.value);
  };

  // const onUsernameChanged = (e) => {
  //   setUserName(e.target.value);
  // };

  const onPasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChanged = (e) => {
    setConfirmPassword(e.target.value);
  };

  const signIn = (e) => {
    e.preventDefault();
    navigate("/financing");
  };

  const signup = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setIsLoading(true);
      console.log("API_URL: ", API_URL);

      AuthService.register(email, password)
        .then(function (response) {
          if (response.status == 201) {
            toast("Verification Email Sent");
            navigate("/financing/login");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          toast(error.response.data.message);
          setIsLoading(false);
          // if (error.response.status == 500) {
          //   toast(error.response.data.message);
          // } else if (error.response.status == 403) {
          //   toast(error.response.data.message);
          // } else {
          //   toast(error.response.data.message);
          // }
        });

      // axios
      //   .post(API_URL + "/api/auth/register", {
      //     email: email,
      //     password: password,
      //   })
      //   .then(function (response) {
      //     if (response.status == 201) {
      //       toast("Verification Email Sent");
      //       navigate("/financing/login");
      //     }
      //     setIsLoading(false);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     if (error.response.status == 500) {
      //       toast(error.response.data.message);
      //     } else if (error.response.status == 403) {
      //       toast(error.response.data.message);
      //     } else {
      //       toast(error.response.data.message);
      //     }
      //     setIsLoading(false);
      //   });
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    // if (userName == "") {
    //   errors["userName"] = "UserName cannot be empty";
    //   formIsValid = false;
    // }
    if (!validateEmail(email)) {
      errors["email"] = "Email address is invalid";
      formIsValid = false;
    }
    if (password == "") {
      errors["password"] = "Password cannot be empty";
      formIsValid = false;
    }
    if (password != confirmPassword) {
      errors["confirmPassword"] = "Passwords doesn't match";
      formIsValid = false;
    }
    if (!termChecked) {
      errors["termChecked"] = "Please agree to Terms & Conditions";
      formIsValid = false;
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };

  return (
    <div className="mb-[200px]">
      <p className="font-helveticaRoman text-default-color text-[2.1875em] text-center mt-[30px]">
        Create an Account
      </p>
      <p className="font-helveticaRoman text-[#393939] text-[1.1625em] sm:text-[1.5625em] text-center mt-[20px]">
        If you are interested in learning more about investing <br />
        in Admiral Securities, please sign up below.
      </p>
      <div className="w-[90%] sm:w-[450px] ml-auto mr-auto mt-[40px] animate__animated  animate__slideInUp">
        <div className="mt-2 mx-auto w-fit">
          <span className="font-helveticaRoman text-[#393939] text-[1.0625em] mr-[10px]">
            Already have an account?
          </span>
          <a href="#" onClick={signIn} className="decoration-[#e54335]">
            <span className="font-helveticaRoman text-[#e54335] text-[1.0625em]">
              Sign In
            </span>
          </a>
        </div>
        {/* <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Username</span>
          <input
            value={userName}
            type="text"
            onChange={onUsernameChanged}
            className="w-[450px] h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["userName"]}</span>
        </div> */}
        <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">
            Email Address
          </span>
          <input
            value={email}
            type="email"
            onChange={onEmailChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["email"]}</span>
        </div>

        {/* <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Phone Number</span>
          <Input
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            className="w-[450px] h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
        </div> */}

        <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">
            Password
          </span>
          <input
            value={password}
            type="password"
            onChange={onPasswordChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["password"]}</span>
        </div>

        <div className="mt-4">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">
            Confirm Password
          </span>
          <input
            value={confirmPassword}
            type="password"
            onChange={onConfirmPasswordChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["confirmPassword"]}</span>
        </div>

        {/* <div className="flex items-center mt-[25px]">
          <input
            type="checkbox"
            checked={termChecked}
            onChange={() => setTermChecked(!termChecked)}
            className="w-[22px] h-[22px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mr-[5px]"
          />
          <label className="font-helveticaRoman text-[#737171] text-[0.9375em] ml-[5px]">
            I agree to
          </label>
          <Link to="/financing/termsandconditions" target="_blank">
            <div className="decoration-default-color underline ml-[5px] cursor-pointer"> 
              <span className="font-helveticaRoman text-default-color text-[.9375em]">
                Terms and Conditions
              </span>
            </div>
          </Link>
        </div>
        <span className="text-red-500">{errors["termChecked"]}</span> */}
        <a
          href="#"
          onClick={signup}
          className="bg-default-color w-full h-[52px] rounded-md flex items-center justify-center mt-[40px]"
        >
          {isLoading ? (
            <MoonLoader color="#FFFFFF" size={25} />
          ) : (
            <span className="font-helveticaRoman text-[1.125em] text-white">
              Sign Up
            </span>
          )}
        </a>
        <p className="font-helveticaRoman text-[#737171] text-[0.875em] mt-[25px]">
          By entering the site, you agree that (a) only you and your authorized
          agents can view and download the documents contained therein, and (b)
          you are responsible for ensuring (i) the security of your username and
          password and (ii) compliance with Admiral’s instructions regarding
          site access and usage.
        </p>
      </div>
    </div>
  );
}

export default FinancingSignup;
