import React, { useState } from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";

function ContactUsForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const onFirstNameChanged = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChanged = (e) => {
    setLastName(e.target.value);
  };

  const onEmailChanged = (e) => {
    setEmail(e.target.value);
  };

  const onMessageChanged = (e) => {
    setMessage(e.target.value);
  };

  const onSendClick = (e) => {
    e.preventDefault();
    console.log("send click");
  };

  return (
    <div className="bg-white rounded-[5px] px-[30px] py-[40px] w-[90%] max-w-[970px] mx-auto animate__animated animate__slideInUp">
      <p className="text-[#0295FF] font-helveticaRoman text-[1.5625em]">Let’s Connect</p>
      <p className="text-[#393939] font-helveticaRoman text-[1.125em] mt-[10px]">Fill out the form below to get started:</p>
      <div className="flex flex-col md:flex-row items-center justify-between gap-[30px] mt-[25px]">
        <div className="w-[100%] md:w-[50%]">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">First Name</span>
          <input
            value={firstName}
            type="text"
            onChange={onFirstNameChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
        </div>
        <div className="w-[100%] md:w-[50%]">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Last Name</span>
          <input
            value={lastName}
            type="text"
            onChange={onLastNameChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between gap-[30px] mt-[25px]">
        <div className="w-[100%] md:w-[50%]">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Email Address</span>
          <input
            value={email}
            type="email"
            onChange={onEmailChanged}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
        </div>
        <div className="w-[100%] md:w-[50%]">
          {/* <span className="font-helveticaRoman text-[#737171] text-[0.9375em] invisible">Phone Number</span> */}
          <p className="font-helveticaRoman text-[#737171] text-[0.9375em] ">I am a(n)</p>
          {/* <Input
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          /> */}
          <div className="w-[200px]  h-[55px] flex items-center mt-2 px-3 border-solid border-[1px] rounded-lg">
            <select className="w-full font-helveticaRoman text-[#737171] text-[0.9375em] h-full">
              <option>Personal investor</option>
              <option>Broker-dealer</option>
              <option>Other</option>
            </select>
          </div>
        </div>
      </div>

      <div className="w-full mt-[25px]">
        <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Message</span>
        <textarea
          value={message}
          type="text"
          onChange={onMessageChanged}
          className="w-full h-[130px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg p-[15px]"
        />
      </div>

      <a href="#" onClick={onSendClick}>
        <div className="w-[250px] h-[52px] bg-[#0295FF] rounded-[5px] flex items-center justify-center mt-[20px]">
          <span className="font-helveticaRoman text-[1.125em] text-white">Send Message</span>
        </div>
      </a>
    </div>
  );
}

export default ContactUsForm;
