import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Hamburger from "hamburger-react";
import { MoonLoader } from "react-spinners";

import styles from "./style.module.css";
import LoginButton from "../../components/loginbutton";
import AboutUsContent from "../../components/AboutusContent";
import PricingForm from "../../components/PricingForm";
import ContactUsForm from "../../components/ContactusForm";
import FAQContent from "../../components/FaqContent";
import faqData from "../../assets/data/faq.json";
import BlueLoginButton from "src/components/loginbutton/blueloginbutton";

function Landing(props) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  // css class state
  const [videoVisibleClass, setVideoVisibleClass] = useState("hidden");
  const [sloganAnimationClass, setSloganAnimationClass] = useState("hidden");
  const [menuTransitionClass, setMenuTransitionClass] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [menuButtonColor, setMenuButtonColor] = useState("#0295ff");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 640) {
      setIsVideoLoaded(true);
    }
  }, [screenWidth]);

  const menuClicked = (toggled) => {
    if (toggled) {
      setMenuTransitionClass(`${styles.menuOpened}`);
      document.body.style.overflow = "hidden";
      setMenuButtonColor("white");
    } else {
      setMenuTransitionClass(`${styles.menuClosed}`);
      document.body.style.overflow = "auto";
      setMenuButtonColor("#0295ff");
    }
  };

  const videoEnded = () => {
    setSloganAnimationClass("visible animate__animated animate__zoomInDown animate__slow");
  };

  const videLoadingEnded = () => {
    setVideoVisibleClass("block");
    setIsVideoLoaded(true);
    props.onVideoLoaded();
  };

  const onItemClickedOnMenu = (link) => {
    navigate(link);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      {/* menu */}
      <div
        className={`fixed w-full h-[0px] left-0 top-0 bg-default-color z-20 overflow-hidden flex flex-col	${menuTransitionClass}`}
      >
        <img src="/images/landing/landing_menu_bg.png" className="absolute -left-1 bottom-0" />
        <div className="h-[83px] w-full" />
        <div className="h-full w-full relative">
          <div className="w-[300px] flex flex-col top-1/2 transform -translate-y-1/2 right-[0px] absolute gap-5">
            <span
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/demo")}
            >
              Demo
            </span>
            <div
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/pricing")}
            >
              Pricing
            </div>
            <div
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/faq")}
            >
              FAQ
            </div>
            {/* <div
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/investors")}
            >
              Institutional Investors
            </div> */}

            <div
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/aboutus")}
            >
              About Us
            </div>

            <div
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/news")}
            >
              News
            </div>

            <div
              className="font-helveticaBold text-[1.5em] text-white cursor-pointer"
              onClick={() => onItemClickedOnMenu("/contactus")}
            >
              Contact Us
            </div>

            {/* <div className="font-helveticaLight text-[1.5em] text-white mt-5">Company</div>
              <div className="flex items-center pl-3 cursor-pointer" onClick={() => onItemClickedOnMenu("/aboutus")}>
                <div className="bg-white w-2 h-2 rounded-[4px]" />
                <div className="font-helveticaBold text-[1.5em] text-white ml-[15px]">About Us</div>
              </div>
              <div className="flex items-center pl-3 cursor-pointer" onClick={() => onItemClickedOnMenu("/news")}>
                <div className="bg-white w-2 h-2 rounded-[4px]" />
                <div className="font-helveticaBold text-[1.5em] text-white ml-[15px]">News</div>
              </div>
              <div className="flex items-center pl-3 cursor-pointer" onClick={() => onItemClickedOnMenu("/contactus")}>
                <div className="bg-white w-2 h-2 rounded-[4px]" />
                <div className="font-helveticaBold text-[1.5em] text-white ml-[15px]">Contact Us</div>
              </div> */}
          </div>
        </div>
      </div>
      {/* spineer */}
      {!isVideoLoaded && (
        <div className="w-screen h-screen flex items-center justify-center">
          <MoonLoader color="#0295ff" />
        </div>
      )}
      {/* header */}
      {isVideoLoaded && (
        <div className="absolute h-[60px] sm:h-[100px] w-full">
          <div className="max-w-[1300px]  h-full mx-auto flex items-center justify-between pl-5">
            <img src="/images/common/bluelogo.png" className="invisible sm:visible w-[200px] object-cover z-20" />
            <div className="w-[150px] lg:w-[180px] h-full flex items-center justify-between z-20">
              <BlueLoginButton />
              <Hamburger duration={0.5} onToggle={menuClicked} color={menuButtonColor} on />
            </div>
          </div>
        </div>
      )}
      {/* video */}
      {
        screenWidth > 640 && (
          <div className="w-full h-[100vh] bg-cover relative ">
            <video
              autoPlay
              muted
              onEnded={videoEnded}
              onLoadedData={videLoadingEnded}
              loop
              className={`w-full h-full object-cover ${videoVisibleClass}`}
            >
              <source src={process.env.PUBLIC_URL + "/video/video.mp4"} type="video/mp4" />
            </video>
          </div>
        )
        // : (
        //   <div className="w-full flex-col items-center absolute mx-auto mt-[250px] ">
        //     <img src="/images/common/bluelogo.png" className="w-[200px] mx-auto " />
        //     <p className="font-helveticaRoman  text-default-color text-[1.1em] text-center mt-7">
        //       Trade Stocks and ETFs with <i>Instant</i> Settlement
        //     </p>
        //   </div>)
      }

      {isVideoLoaded && (
        <>
          {/* text */}
          {/* <div
            style={{ backgroundImage: "url('/images/landing/bg.png')" }}
            className="w-full h-screen  bg-cover flex items-center justify-center"
          >
            <AnimationOnScroll
              animateIn="animate__animated animate__zoomInDown animate__slow"
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <div>
                <p className="font-helveticaBold  text-white text-[2em] sm:text-[4em] text-center">The Future Today</p>
                <p className="font-helveticaRoman  text-white text-[1.2em] sm:text-[2.5em] text-center">
                  Trade Stocks and ETFs with <i>Instant</i> Settlement
                </p>
              </div>
            </AnimationOnScroll>

          </div> */}

          <div className="bg-white h-screen pt-[150px] sm:pt-[200px] sm:border-t-8 sm:border-default-color">
            <AnimationOnScroll
              animateIn="animate__animated animate__slideInUp"
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <>
                <img src="/images/common/bluelogo.png" className="w-[250px] sm:w-[350px] mx-auto" />

                <p className="font-helveticaRoman  text-default-color text-[1.1em] sm:text-[1.3em] text-center mt-7">
                  With Admiral, you will be able to trade stocks and ETFs with instant settlement.
                </p>
                <p className="font-helveticaRoman  text-default-color text-[1.1em] sm:text-[1.3em] text-center mt-1">
                  To make this possible, Admiral will operate an Alternative Trading System.
                </p>
                <div className="font-helveticaRoman  text-default-color text-[1.1em] sm:text-[1.3em] text-center mt-1">
                  If you are interested in trading on our platform, please click&nbsp;
                  <a onClick={(e) => navigate("/contactus")} className="underline cursor-pointer">
                    here
                  </a>
                  &nbsp;to contact us.
                </div>
              </>
            </AnimationOnScroll>
          </div>

          <div className="bg-gray-200 w-full py-[20px] sm:py-[100px]">
            <AnimationOnScroll
              animateIn="animate__animated animate__slideInUp"
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <PricingForm />
            </AnimationOnScroll>
          </div>
          <div id="contactus" className="bg-default-color w-full py-[100px] rounded-tr-[50px] rounded-bl-[50px]">
            <AnimationOnScroll
              animateIn="animate__animated animate__slideInUp "
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <p className="font-helveticaBold text-[2.2em] md:text-[2.8em] text-center text-white mb-[40px]">
                Contact Us Today!
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__animated animate__slideInUp animate__delay-1s"
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <ContactUsForm />
            </AnimationOnScroll>
          </div>

          <div className="bg-white w-full py-[100px] ">
            <AnimationOnScroll
              animateIn="animate__animated animate__slideInUp animate__delay-0.5s"
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <FAQContent faqData={faqData} initialCount={5} />
            </AnimationOnScroll>
          </div>

          <div className="bg-gray-bg-color w-full py-[70px]">
            <AnimationOnScroll
              animateIn="animate__animated animate__slideInUp"
              animateOnce={true}
              // scrollableParentSelector=".scrollableContainer"
            >
              <AboutUsContent />
            </AnimationOnScroll>
          </div>
        </>
      )}
    </>
  );
}

export default Landing;
