import React from "react";
import { useNavigate } from "react-router-dom";

function LoginButton() {
  const navigate = useNavigate();

  const loginClicked = (e) => {
    navigate("/login");
  };

  return (
    <div
      className="w-[90px] h-[36px] border rounded-[18px] flex items-center justify-center cursor-pointer"
      onClick={loginClicked}
    >
      <span className="text-white text-base font-helveticaBold">LOG IN</span>
    </div>
  );
}

export default LoginButton;
