import { createSlice } from "@reduxjs/toolkit";
import { isAuthenticatedWithToken } from "src/pages/Financing/Common/common";

const initialState = {
  isAuthenticated: isAuthenticatedWithToken(),
  token: "",
  tokenExpiresIn: "",
  user: "",
};

export const financingAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setTokenState: (state, action) => {
      state.token = action.payload;
    },
    setTokenExpiresInState: (state, action) => {
      state.tokenExpiresIn = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setAuthState, setTokenState, setTokenExpiresInState, setUser } = financingAuthSlice.actions;

export default financingAuthSlice.reducer;
