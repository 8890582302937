import React, { useState } from "react";

function Demo() {
  return (
    <div className="w-full h-[1000px]">
      <p className="mt-[200px] text-center font-helveticaRoman text-[2.0em]">Coming soon</p>
    </div>
  );
}

export default Demo;
