import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AuthService from "../Financing/Services/auth.service";

function EmailVerification() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [message, setMessage] = useState("");
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    AuthService.verifyEmail(token)
      .then((response) => {
        if (response.status == 200) {
          setIsVerified(true);
          setMessage("Your email address has been successfully  verified.");

          const intervalId = setInterval(() => {
            console.log("seconds: ", seconds);

            setSeconds((prevSeconds) => prevSeconds - 1);
          }, 1000);

          return () => {
            clearInterval(intervalId);
          };
        } else {
          setMessage("Something went wrong");
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (seconds == 0) {
      navigate("/financing/login");
    }
  }, [seconds]);

  return (
    <div className="pt-10">
      <p className="font-helveticaRoman text-black text-[20px] sm:text-[26px] text-center">
        {message}
      </p>
      {isVerified && (
        <p className="font-helveticaRoman text-black text-[20px] sm:text-[26px] text-center">
          Redirecting in {seconds}...
        </p>
      )}
    </div>
  );
}

export default EmailVerification;
