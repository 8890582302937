import React from "react";
import { useNavigate, Link } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="h-[310px] bg-footer-bg w-full">
      <div className="h-[230px] w-full items-center justify-center flex">
        <div className="w-full max-w-[1200px] h-[145px] gap-[20px] md:gap-0 items-start md:items-center justify-between flex flex-col md:flex-row px-[20px]">
          <div className="w-[90%] sm:w-[500px] h-full flex items-center justify-between">
            {/* <p className="h-[28px] font-helveticaLight text-[1.375em] text-white">Our Company</p> */}
            <div className="flex flex-col items-center justify-between w-[55px] h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/demo")}
              >
                Demo
              </p>
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer"
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                Pricing
              </p>
            </div>

            <div className="flex flex-col items-center justify-between w-[80px] h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/faq")}
              >
                FAQ
              </p>
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/aboutus")}
              >
                About Us
              </p>
            </div>

            <div className="flex flex-col items-center w-[90px] justify-between h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer"
                onClick={() => {
                  navigate("/news");
                }}
              >
                News
              </p>
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/contactus")}
              >
                Contact Us
              </p>
            </div>

            {/* <div className="w-[170px] h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer"
                onClick={() => {
                  navigate("/investors");
                }}
              >
                Institutional Investors
              </p>
            </div> */}
          </div>

          <div className="w-[230px] h-full">
            <p className="h-[28px] font-helveticaLight text-[1.0em] md:text-[1.375em] text-white">Follow Us</p>
            <div className="w-full items-center justify-between flex mt-[10px]">
              <img src="/images/footer/google_icon.png" />
              <img src="/images/footer/facebook_icon.png" />
              <img src="/images/footer/linkedin_icon.png" />
              <img src="/images/footer/youtube_icon.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-230px)] w-full relative flex items-center justify-center">
        <div className="w-[80%] absolute h-[1px] bg-white opacity-[18%] top-0 left-[10%]"></div>
        <p className="font-helveticaLight text-[0.9375em] text-white">Copyright © 2023 Admiral. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
