import { toast } from "react-toastify";

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validatePassword = (password) => {
  let response = { success: true, message: "" };
  if (password.length < 6) {
    response.success = false;
    response.message = "Password must be at least 6 letters";
  }

  return response;
};

export const API_URL =
  process.env.NODE_ENV == "production"
    ? "https://admiral.trade"
    : "http://localhost:5000";

export const handleError = (error) => {
  console.log("error: ", error);
  if (error.response) {
    toast(error.response.data.message);
  } else {
    toast("An error occured");
  }
};

export const getImageUrl = (imagePath) => {
  return process.env.PUBLIC_URL + imagePath;
};
