import React, { useEffect, useState } from "react";

import FAQContent from "../../components/FaqContent";

function FAQ() {
  return (
    <div className="w-full flex flex-col items-center py-[80px] min-h-[1000px]">
      <FAQContent />
    </div>
  );
}

export default FAQ;
