// import jwt_decode from "jwt-decode";

export const isAuthenticatedWithToken = () => {
  const token = localStorage.getItem("financing_accessToken"); // or sessionStorage.getItem('jwtToken');

  if (token) {
    // try {
    //   const { exp } = jwt_decode(token);
    //   return exp > Date.now() / 1000;
    // } catch (error) {
    //   return false;
    // }
    return true;
  }

  return false;
};
