import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "animate.css";

import LoginButton from "../loginbutton";
import LogoutButton from "../LogoutButton";
import { setAuthState } from "src/redux/slices/financingAuthSlice";

function InvestorHeader() {
  const navigate = useNavigate();
  const [isShowDropDown, setShowDropDown] = useState(false);
  const isAuthenticated = useSelector((state) => state.financingAuth.isAuthenticated);
  const dispatch = useDispatch();

  const contactUsClicked = (e) => {
    e.preventDefault();
    navigate("/contactus");
  };

  const newsClicked = (e) => {
    e.preventDefault();
    navigate("/news");
  };

  const pricingClicked = (e) => {
    e.preventDefault();
    navigate("/pricing");
  };

  const faqClicked = (e) => {
    e.preventDefault();
    navigate("/faq");
  };

  const aboutUsClicked = (e) => {
    e.preventDefault();
    navigate("/aboutus");
  };

  const demoClicked = (e) => {
    e.preventDefault();
    navigate("/demo");
  };

  const investorsClicked = (e) => {
    e.preventDefault();
    navigate("/investors");
  };

  const companyMouseEnter = () => {
    setShowDropDown(true);
  };
  const companyMouseLeave = () => {
    setShowDropDown(false);
  };

  const logoClicked = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const logOutclicked = () => {
    localStorage.removeItem("financing_accessToken");
    localStorage.removeItem("financing_tokenExpiresIn");
    localStorage.removeItem("financing_user");
    dispatch(setAuthState(false));
    navigate("/financing/login");
  };

  return (
    <div className="h-[83px] bg-default-color w-full relative px-[40px]">
      <div className="absolute left-0 right-0 top-[100%] w-full h-[2px] bg-white" /> {/* white line at the bottom */}
      <div className="mx-auto h-full flex items-center justify-between max-w-[1246px]">
        <a href="#" onClick={logoClicked}>
          <img src="/images/common/logo.png" className="w-[138px] sm:w-[188px] " /> {/* logo */}
        </a>
        {isAuthenticated && <LogoutButton onClick={logOutclicked} />}

        {/* <div className="w-[827px] flex items-center justify-between relative h-full">
          <a href="#" onClick={demoClicked}>
            <span className="font-helveticaRoman text-header-text-color">Demo</span>
          </a>
          <a href="#" onClick={pricingClicked}>
            <span className="font-helveticaRoman text-header-text-color">Pricing</span>
          </a>
          <a href="#" onClick={faqClicked}>
            <span className="font-helveticaRoman text-header-text-color">FAQ</span>
          </a>
          <a href="#" onClick={investorsClicked}>
            <span className="font-helveticaRoman text-header-text-color">Institutional Investors</span>
          </a>
          <div className="h-full flex items-center">
            <div
              className="flex items-center cursor-pointer relative"
              onMouseEnter={companyMouseEnter}
              onMouseLeave={companyMouseLeave}
            >
              <span className="font-helveticaRoman text-header-text-color mr-[14px]">Company</span>
              <img src="/images/header/header_arrow.png" />

              {isShowDropDown && (
                <div className="absolute z-10 w-[145px] h-[140px] bg-default-color top-[20px] flex flex-col items-center justify-between px-[10px] pb-[10px] pt-[40px]">
                  <a href="#" className="w-full" onClick={aboutUsClicked}>
                    <span className="text-white text-base font-helveticaRoman">About Us</span>
                  </a>
                  <a href="#" className="w-full" onClick={newsClicked}>
                    <span className="text-white text-base font-helveticaRoman">News</span>
                  </a>
                  <a href="#" className="w-full" onClick={contactUsClicked}>
                    <span className="text-white text-base font-helveticaRoman">Contact Us</span>
                  </a>
                </div>
              )}
            </div>
          </div>
          <LoginButton />
        </div> */}
      </div>
    </div>
  );
}

export default InvestorHeader;
