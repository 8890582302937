import React, { useState } from "react";

function PricingForm() {
  const basicClicked = (e) => {
    e.preventDefault();
  };
  const premiumClicked = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-[90%] md:max-w-[1150px] rounded-[10px] bg-white mx-auto py-[80px] animate__animated  animate__slideInUp">
      <p className="text-[1.7625em] sm:text-[2.35625em] text-black font-helveticaBold  text-center">
        Simple, transparent pricing
      </p>
      <div className="w-full mt-[50px]">
        <div className="max-w-[1000px] h-[400px] mx-auto flex-col md:flex-row flex items-center justify-between gap-[20px]">
          <p className="text-[1.7625em] sm:text-[1.65625em] text-black font-helveticaRoman w-full  text-center">
            Coming soon
          </p>

          {/* <div className="w-[90%] sm:w-[450px] h-[330px] rounded-[10px] border pt-[20px] relative">
            <div className="w-full">
              <img src="/images/pricing/basic_pricing.png" className="w-[45px] h-[45px] mx-auto" />
            </div>
            <p className="text-[1.5em] text-default-color font-helveticaBold  text-center">BASIC PLAN</p>
            <p className="text-[1.5em] text-black font-helveticaBold  text-center">$0/month</p>
            <div className="w-[90%] flex items-center justify-start mx-auto mt-[30px]">
              <img src="/images/pricing/bullet.png" className="w-[22px] h-[22px]" />
              <p className="text-[0.8em] text-gray-500 font-helveticaLight text-center ml-[5px]">$0.10/trade</p>
            </div>

            <a href="#" onClick={basicClicked}>
              <div className="absolute left-[5%] bottom-[15px] rounded-[5px] bg-default-color w-[90%] h-[40px] flex items-center justify-center mx-auto mt-[80px]">
                <span className="text-white font-helveticaRoman text-[0.8em]">Get started</span>
              </div>
            </a>
          </div>

          <div className="relative w-[90%] sm:w-[450px] h-[330px] rounded-[10px] border pt-[20px]">
            <div className="w-full">
              <img src="/images/pricing/premium_pricing.png" className="w-[45px] h-[45px] mx-auto" />
            </div>
            <p className="text-[1.5em] text-[#E7C302] font-helveticaBold  text-center">PREMIUM PLAN</p>
            <p className="text-[1.5em] text-black font-helveticaBold  text-center">$4/month</p>
            <div className="w-[90%] flex items-center justify-start mx-auto mt-[30px]">
              <img src="/images/pricing/bullet.png" className="w-[22px] h-[22px]" />
              <p className="text-[0.8em] text-gray-500 font-helveticaLight text-center ml-[5px]">
                80 free trades per month ($0.10 per trade thereafter)
              </p>
            </div>
            <div className="w-[90%] flex items-center justify-start mx-auto mt-[10px]">
              <img src="/images/pricing/bullet.png" className="w-[22px] h-[22px]" />
              <p className="text-[0.8em] text-gray-500 font-helveticaLight text-center ml-[5px]">
                Anchor: Admiral's Proprietary Portfolio Comparison Tool
              </p>
            </div>
            <a href="#" onClick={premiumClicked}>
              <div className="absolute left-[5%] bottom-[15px] rounded-[5px] bg-[#E7C302] w-[90%] h-[40px] flex items-center justify-center mx-auto mt-[48px]">
                <span className="text-white font-helveticaRoman text-[0.8em]">Get started</span>
              </div>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PricingForm;
