import React, { useState } from "react";

function Investors() {
  const ATSclicked = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-full h-[1000px] py-[100px] px-[10px]">
      <p className="font-helveticaRoman text-[1.1em] sm:text-[1.6em] text-left w-[90%] max-w-[1000px] mx-auto">
        To make instant settlement possible, Admiral operates an Alternative Trading System (ATS). We welcome
        institutional investors to the ATS, especially those who wish to act as market makers. Our ATS charges
        competitive rates.
      </p>
      <br />
      <p className="font-helveticaRoman text-[1.1em] sm:text-[1.6em] text-left w-[90%] max-w-[1000px] mx-auto">
        Our form ATS-N can be found{" "}
        <a href="#" className="underline text-blue-600" onClick={ATSclicked}>
          here
        </a>
        .
      </p>
      <br />
      <p className="font-helveticaRoman text-[1.1em] sm:text-[1.6em] text-left w-[90%] max-w-[1000px] mx-auto">
        For more information please contact us at: institutional@admiral.trade
      </p>
    </div>
  );
}

export default Investors;
