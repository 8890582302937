import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Member from "../member";
import MemberModal from "../membermodal";

const memberList = [
  {
    name: "Gideon Magnus",
    job: "Founder and Chief Executive Officer",
    photo: "/images/aboutus/gideon.jpeg",
    bio: "bio",
  },
  {
    name: "John Byrne",
    job: "Chief Compliance Officer",
    photo: "/images/aboutus/john.jpg",
    bio: "bio",
  },
  {
    name: "Keith George",
    job: "Finance and Operations Principal",
    photo: "/images/aboutus/keith.jpg",
    bio: "bio",
  },
  // {
  //   name: "Christopher Meade",
  //   job: "Chief Compliance Officer",
  //   photo: "/images/aboutus/chris.jpg",
  //   bio: "bio",
  // },
  {
    name: "Nathan Tribble",
    job: "Chief Revenue Officer",
    photo: "/images/aboutus/nathan.jpg",
    bio: "bio",
  },
  {
    name: "David Weiss",
    job: "Chief Operations Officer",
    photo: "/images/aboutus/david.jpg",
    bio: "bio",
  },
];

function AboutUsContent() {
  const [isModalShown, setIsModalShown] = useState(false);
  const [clickedMember, setClickedMember] = useState(null);

  const memberClicked = (member) => {
    setClickedMember(member);
    setIsModalShown(true);
  };

  const closeModalClicked = () => {
    setIsModalShown(false);
  };

  return (
    <>
      <div className="animate__animated  animate__slideInUp">
        <p className="font-helveticaBold text-[2.2em] md:text-[2.9em] text-center">WHO WE ARE</p>
      </div>

      <div
        className="w-[90%] max-w-[1000px] mx-auto mt-[100px] animate__animated  animate__slideInUp animate__delay-1s"
        // style={{ gridTemplateColumns: "auto auto auto", gridTemplateRows: "auto auto" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px] mx-auto mt-8 animate__animated animate__slideInUp animate__delay-1s">
          {memberList.map((member, i) => {
            return (
              <div className="flex flex-col items-center">
                <Member member={member} memberClicked={memberClicked} key={i} />
              </div>
            );
          })}
        </div>
      </div>

      {isModalShown && <MemberModal member={clickedMember} closeClicked={closeModalClicked} />}
    </>
  );
}

export default AboutUsContent;
