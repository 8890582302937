import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { validateEmail, API_URL } from "src/common/function";

function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onEmailChanged = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const forgotPassword = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setIsLoading(true);
      axios
        .get(API_URL + "/api/auth/forgot-password/" + email)
        .then(function (response) {
          console.log(response);
          if (response.status == 200) {
            toast("Reset Email Sent");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
          toast(error.response.data.message);
        });
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!validateEmail(email)) {
      errors["email"] = "Email address is invalid";
      formIsValid = false;
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };

  return (
    <div className="mt-[81px] mb-[200px]">
      <div className="h-full w-[90%] max-w-[503px] mx-auto pt-[5px] animate__animated  animate__slideInUp">
        <p className="font-helveticaRomanRoman text-default-color text-[2.1875em] text-center">
          Forgot Password
        </p>

        <div className="mt-5">
          <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">
            Email address
          </span>
          <input
            value={email}
            type="email"
            onChange={onEmailChanged}
            className="w-full max:w-[450px] h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
          />
          <span className="text-red-500">{errors["email"]}</span>
        </div>

        <a
          href="#"
          onClick={forgotPassword}
          className="bg-default-color w-full h-[52px] rounded-md flex items-center justify-center mt-[40px]"
        >
          {isLoading ? (
            <MoonLoader color="#FFFFFF" size={25} />
          ) : (
            <span className="font-helveticaRoman text-[1.125em] text-white">
              Forgot Password
            </span>
          )}
        </a>
      </div>
    </div>
  );
}

export default ForgotPassword;
