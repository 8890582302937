import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

function SlideDeck() {
  useEffect(() => {});

  return (
    <div className="mt-[81px] mb-[200px]">
      <p className="text-center">SlideDeck</p>
    </div>
  );
}

export default SlideDeck;
