import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import parse from "html-react-parser";

import styles from "./styles.module.css";
import faqData from "../../assets/data/faq.json";

const initialCount = 10;

function FAQContent(props) {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState(faqData);
  const [showingData, setShowingData] = useState(data.slice(0, initialCount));

  useEffect(() => {
    searchFaq(keyword);
  }, [keyword]);

  const keywordChanged = (e) => {
    const keywordString = e.target.value;
    setKeyword(keywordString);
    searchFaq(keywordString);
  };

  const searchFaq = (keywordString) => {
    let newData;
    if (keywordString.length === 0) {
      newData = faqData;
    } else {
      newData = search(keywordString);
    }
    setData(newData);
    setShowingData(newData.slice(0, initialCount));
  };

  const search = (keyword) => {
    let result = [];
    for (let element of faqData) {
      if (element.question.includes(keyword) || element.answer.includes(keyword)) {
        result.push(element);
      }
    }
    return result;
  };

  const eraseKeyword = () => {
    setKeyword("");
  };

  const loadMore = (e) => {
    e.preventDefault();

    const filtered = data.slice(0, showingData.length + 3);
    console.log(filtered);
    setShowingData(filtered);
  };

  return (
    <div className="w-[90%] max-w-[1000px] flex flex-col items-center justify-between mx-auto animate__animated  animate__slideInUp">
      <p className="font-helveticaBold text-[2.2em] text-center text-[#0F0F1E]">Frequently Asked Questions</p>
      <div className="w-full h-[40px] rounded-[20px] flex items-center border-[2px] px-[10px] mt-[40px] mb-[30px]">
        <img src="images/faq/search_icon.png" className="w-[20px] h-[20px]" />
        <input
          type="text"
          placeholder="What are you looking for?"
          value={keyword}
          onChange={keywordChanged}
          className="ml-[10px] w-full"
        />
        <a href="#" onClick={eraseKeyword}>
          <img src="images/faq/close_button.png" className="w-[20px] h-[20px]" />
        </a>
      </div>

      <Accordion transition transitionTimeout={200} allowMultiple className="w-full">
        {showingData.map((item, i) => (
          <AccordionItem header={item.question} key={i}>
            {parse(item.answer)}
          </AccordionItem>
        ))}
      </Accordion>

      {data.length > showingData.length && (
        <a href="#" onClick={loadMore}>
          <button className="text-[1.0em] font-helveticaRoman mx-auto border-[1px] rounded-[5px] px-[20px] py-[5px]">
            Load More ...
          </button>
        </a>
      )}
    </div>
  );
}

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className={styles.chevron} src="/images/faq/arrow-down.svg" alt="Chevron Down" />
      </>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) => `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
    }}
    contentProps={{ className: styles.itemContent }}
    panelProps={{ className: styles.itemPanel }}
  />
);

export default FAQContent;
