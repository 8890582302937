import React, { useState } from "react";
import PricingForm from "../../components/PricingForm";

function Pricing() {
  const basicClicked = (e) => {
    e.preventDefault();
  };
  const premiumClicked = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-full bg-[#F7F6FA] py-[100px]">
      <PricingForm />
    </div>
  );
}

export default Pricing;
