import React from "react";

function MemberModal(props) {
  const closeClicked = () => {
    props.closeClicked();
  };

  const onBackgroundClicked = (e) => {
    props.closeClicked();
  };

  const facebookClicked = (e) => {
    e.preventDefault();
  };
  const twitterClicked = (e) => {
    e.preventDefault();
  };
  const linkedinClicked = (e) => {
    e.preventDefault();
  };
  const modalClicked = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed left-0 right-0 top-0 bottom-0 bg-black bg-opacity-80 z-20 flex items-center justify-center"
      onClick={onBackgroundClicked}
    >
      <div
        className="w-[300px] sm:w-[700px]  flex-col sm:flex-row flex items-center justify-between relative"
        onClick={modalClicked}
      >
        <img
          src="/images/aboutus/close_btn.png"
          className="w-[35px] h-[35px] absolute top-2 right-2 cursor-pointer z-30"
          onClick={closeClicked}
        />
        <img src={props.member.photo} className="w-[300px] sm:w-[380px] h-[400px] object-cover" />
        <div className="bg-white w-[300px] sm:w-full h-[350px] sm:h-[400px] relative p-[35px]">
          <p className="text-default-color font-helveticaRoman text-[1.5em] sm:text-[2.2em]">{props.member.name}</p>
          <p className="text-black font-helveticaRoman text-[1.0em]">{props.member.job}</p>
          <p className="text-[#737171] font-helveticaRoman text-[1.0em] mt-[15px] h-[150px]">{props.member.bio}</p>

          <div className="flex items-center justify-start mt-[20px] gap-3">
            <a href="#" onClick={facebookClicked}>
              <img src="/images/aboutus/modal_facebook.png" className="w-[45px] h-[45px]" />
            </a>
            <a href="#" onClick={twitterClicked}>
              <img src="/images/aboutus/modal_twitter.png" className="w-[45px] h-[45px]" />
            </a>
            <a href="#" onClick={linkedinClicked}>
              <img src="/images/aboutus/modal_linkedin.png" className="w-[45px] h-[45px]" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberModal;
